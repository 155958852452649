<app-loading *ngIf="isLoading; else content"></app-loading>

<ng-template #content>
  <div *ngIf="isTabsShow" class="tabs" [class.fixed]="tabs.isSticky">
    <button
      *ngIf="isBackTabShow; else tabsButtons"
      class="tab tab__full"
      [class.tab__main] = "tabs.activeId == 'tabMain'"
      [class.tab__happy_hours] = "tabs.activeId == 'tabHappyHours'"
      (click)="setActiveTab(tabs.activeId)">
      ◁ {{ 'menu.tabs.backToCategory' | translate }}
    </button>

    <ng-template #tabsButtons>
      <button class="tab tab__main" [class.tab__active]="tabs.activeId == 'tabMain'" (click)="setActiveTab('tabMain')">
        {{ 'menu.tabs.main' | translate }}
      </button>
      <button class="tab tab__happy_hours" [class.tab__active]="tabs.activeId == 'tabHappyHours'" (click)="setActiveTab('tabHappyHours')">
        {{ 'menu.tabs.happyHours' | translate }}
      </button>
    </ng-template>

    <span class="glider"></span>
  </div>

  <div class="box-menu" *ngIf="tabs.activeId == 'tabMain'">
    <div class="categories" [class.categories__tabs]="isTabsShow" *ngIf="!mainMenusCurrent">

      <div class="categories__title">
        <h2>Menu</h2>
      </div>

      <button class="category" *ngFor="let menu of mainMenus" (click)="setMainMenusCurrent(menu)">
        {{ menu.name }}
      </button>

      <div class="empty" *ngIf="mainMenus.length === 0">
        <img src="/themes/jack-astors/assets/images/more/menu.svg" alt="" />
        <div>{{ 'menu.empty' | translate }}</div>
      </div>
    </div>

    <div class="menu" [class.menu__tabs]="isTabsShow" *ngIf="mainMenusCurrent">
      <pinch-zoom *ngFor="let url of mainMenusCurrent.pagesLocal" [properties]="pinchZoomProperties">
        <img [src]="url" loading="lazy" alt="">
      </pinch-zoom>
    </div>
  </div>

  <div class="box-menu" *ngIf="tabs.activeId == 'tabHappyHours'">
    <div class="categories" [class.categories__tabs]="isTabsShow" *ngIf="!happyHoursMenusCurrent">
      <button class="category" *ngFor="let menu of happyHoursMenus" (click)="setHappyHoursMenusCurrent(menu)">
        {{ menu.name }}
      </button>

      <div class="empty" *ngIf="happyHoursMenus.length === 0">
        <img src="/themes/dennys/assets/images/menu.svg" alt="" />
        <div>{{ 'menu.empty' | translate }}</div>
      </div>
    </div>

    <div class="menu" [class.menu__tabs]="isTabsShow" *ngIf="happyHoursMenusCurrent">
      <pinch-zoom *ngFor="let url of happyHoursMenusCurrent.pagesLocal" [properties]="pinchZoomProperties">
        <img [src]="url" loading="lazy" alt="">
      </pinch-zoom>
    </div>
  </div>

</ng-template>

