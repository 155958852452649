import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, distinctUntilChanged, mapTo, map, tap, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeyValueStorageService } from './key-value.storage.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private static readonly tokenKey = 'token';

  public readonly demoMode$ = new BehaviorSubject<boolean>(false);

  private readonly token$ = new BehaviorSubject<string | null | undefined>(undefined);

  private readonly authorized$ = this.token$.pipe(
    distinctUntilChanged(),
    filter((token) => token !== undefined),
    map((token) => !!token),
  );

  public readonly loading$ = this.token$.pipe(
    map((token) => token === undefined),
    distinctUntilChanged(),
  );

  public readonly logouted$ = this.authorized$.pipe(
    distinctUntilChanged(),
    filter((yes) => !yes),
    mapTo(true)
  );

  public readonly logined$ = this.authorized$.pipe(
    distinctUntilChanged(),
    filter((yes) => yes),
    mapTo(true)
  );

  constructor(
    private readonly keyValueStorage: KeyValueStorageService,
  ) {
    const tokenFlow$ = this.keyValueStorage.get(AuthService.tokenKey).pipe(
      map((token) => {
        if (token && token.value) {
          return token.value;
        }

        return localStorage.getItem(AuthService.tokenKey);
      }),
      tap((token) => {
        if (token) {
          this.saveToken(token);
        } else {
          this.removeToken();
        }
      }),
    );

    this.demoMode$.pipe(
      distinctUntilChanged(),
      switchMap((demoMode) => {
        if (demoMode) {
          this.token$.next('demo');
        }

        return tokenFlow$;
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  public get isDemoMode(): boolean {
    return this.demoMode$.getValue();
  }

  public login(token: string): void {
    this.saveToken(token);
  }

  public logout(): void {
    this.removeToken();
  }

  public getToken(): string | null {
    return this.token$.getValue() ?? null;
  }

  private saveToken(token: string): void {
    this.keyValueStorage.set({
      key: AuthService.tokenKey,
      value: token,
    }).subscribe();
    this.token$.next(token);
    localStorage.setItem(AuthService.tokenKey, token);
  }

  private removeToken(): void {
    this.keyValueStorage.delete(AuthService.tokenKey).subscribe();
    this.token$.next(null);
    localStorage.removeItem(AuthService.tokenKey);
  }

}
